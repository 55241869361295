import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Navbar as NavbarBs } from "react-bootstrap";

function DropdownMenu() {
  const [navLinks, setNavLinks] = useState([]);


  useEffect(() => {
    const navs = [
      { name: <strong>WEBBSHOP</strong>, path: "/" },
      { name: "- Apcalis Oral Jelly", path: "/Produkter/Tadalafil/ApcalisOralJelly" },
      { name: "- Cialis", path: "/Produkter/Tadalafil/Cialis" },
      { name: "- Kamagra", path: "/Produkter/Sildenafil/Kamagra" },
      { name: "- Kamagra Gold", path: "/Produkter/Sildenafil/KamagraGold" },
      { name: "- Kamagra Oral Jelly", path: "/Produkter/Sildenafil/KamagraOralJelly" },
      { name: "- Lovegra", path: "/Produkter/Sildenafil/Lovegra" },
      { name: "- Super Kamagra", path: "/Produkter/Sildenafil/SuperKamagra" },
      { name: "- Valif Oral Jelly", path: "/Produkter/Vardenafil/ValifOralJelly" },
      { name: "- Viagra", path: "/Produkter/Sildenafil/Viagra" },
      { name: "- Vidalista", path: "/Produkter/Tadalafil/Vidalista" },
      { name: <strong>FAQ</strong>, path: "/FAQ" },
      { name: <strong>OM OSS / KONTAKT</strong>, path: "/OmOss" },
     
    ];
    setNavLinks(navs);
  }, []);

  return (
    <NavbarBs>
      <button
        type="button"
        className="btn btn-dark dropdown-toggle"
        data-bs-toggle="dropdown"
        data-bs-display="static"
        aria-expanded="false"
      >
        Meny
      </button>
      <ul className="dropdown-menu dropdown-menu-end">
        {navLinks.map((d, i) => (
          <li key={i}>
            <Link to={d.path}>
              <button className="dropdown-item" type="button">
                {d.name}
              </button>
            </Link>
          </li>
        ))}
      </ul>
    </NavbarBs>
  );
}

export default DropdownMenu;
