import React from "react";
import { Button, Stack } from "react-bootstrap";
import { useShoppingCart } from "../../context/ShoppingCartContext";
import storeItems from "../../data/items.json";

export function CartItem({ id, quantity }) {
  const { increaseCartQuantity, decreaseCartQuantity } = useShoppingCart();
  const item = storeItems.find((i) => i.id === id);
  if (item == null) return null;

  const handleIncreaseQuantity = () => {
    const totalQuantityInCart = quantity + 1;
    if (totalQuantityInCart <= item.stock) {
      increaseCartQuantity(item.id);
    } else {
      //Ignore
    }
  };

  return (
    <Stack direction="horizontal" gap={2} className="d-flex align-items-center">
      
      <div className="me-auto">
        <div>
          <img
            src={item.imgUrl}
            alt={item.name}
            style={{ width: "40px", height: "40px", objectFit: "cover" }}
          />
          <strong>{item.shortname}</strong>
          <div>
            {item.quantities}-Pack ({item.price} kr)
          </div>
        </div>
      </div>

      <div>  
        <div className="d-flex align-items-right justify-content-center">      
          <Button className="bg-dark" onClick={() => decreaseCartQuantity(item.id)}>-</Button> 
          <div className="quantity">  
            {quantity}    
          </div>
          {quantity < item.stock ? (
            <Button className="bg-dark" onClick={handleIncreaseQuantity}>+</Button>
          ) : (
            <Button disabled className="increaseQuantity-button">+</Button>
          )}
        </div>
      </div>
      
    </Stack>
  );
}
