import { FaGift } from "react-icons/fa";
import { IoCartSharp } from "react-icons/io5";

export function Banner() {
  return (
    <div style={{ backgroundColor: '#f8f9fa', height: 'auto' }}>
      <div className="justify-content-center pt-3 pb-1">
        <h2 style={{ 
          fontSize: '14px', 
          textAlign: 'center', 
          paddingLeft: '10px', 
          paddingRight: '10px' 
        }}>
          <FaGift size={18}/> Handla för 1000 kr och få en GRATIS Viagra-karta! Använd rabattkoden "VIAGRA" vid kassan. <IoCartSharp size={22} />
        </h2>
      </div>
    </div>
  );
}

export default Banner;
