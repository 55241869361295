import React, { useState} from "react";
import { Offcanvas, Stack, Button } from "react-bootstrap";
import { useShoppingCart } from "../../context/ShoppingCartContext";
import { formatCurrency } from "../../utilities/formatCurrency";
import { CartItem } from "./CartItem";
import storeItems from "../../data/items.json";

type ShoppingCartProps = {
  isOpen: boolean;
};

export function ShoppingCart({ isOpen }: ShoppingCartProps) {
  const { closeCart, cartItems, cartQuantity } = useShoppingCart();
  const [error] = useState("");

  const subtotal = cartItems.reduce((total, cartItem) => {
    const item = storeItems.find((i) => i.id === cartItem.id);
    return total + (item?.price || 0) * cartItem.quantity;
  }, 0);

  let shippingCharges = 49;

  if ((subtotal <= 0) || (subtotal  >= 499)) {
    shippingCharges = 0;
  }

  const total = subtotal + shippingCharges; 

  return (
    <>
      <Offcanvas show={isOpen} onHide={closeCart} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Din varukorg ({cartQuantity})</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Stack gap={3}>
            {cartItems.map((item) => (
              <CartItem key={item.id} {...item} />
            ))}
            {error && <div className="text-danger">{error}</div>}
            <div className="ms-auto fw-bold fs-5">
              Belopp {formatCurrency(subtotal)}
            </div>
           
            <div className="ms-auto fw-bold fs-5" style={{ marginBottom: "-20px" }}>
              {shippingCharges === 0
                ? "Gratis frakt"
                : `Frakt ${formatCurrency(shippingCharges)}`}
            </div>

            <div className="ms-auto">
              {shippingCharges === 0
                  ? ""
                  : <span style={{ fontSize: "smaller", color: "grey", fontStyle: "italic" }}>
                      Handla för {formatCurrency(499 - subtotal)} till för att få fri frakt.
                    </span>}
            </div>

            <div className="ms-auto fw-bold fs-5">
              Totalbelopp {formatCurrency(total)}
            </div>
            <div className="ms-auto fw-bold fs-5">
              {total > 0 ? (
                <Button
                  className="bg-dark"
                  href="/Kassa"
                >
                  Gå till kassan
                </Button>
              ) : (
                <Button
                  href="/Kassa"
                  style={{ backgroundColor: "grey" }}
                  disabled
                >
                  Gå till kassan
                </Button>
              )}
            </div>
          </Stack>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}