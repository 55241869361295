import React, { createContext, useContext, useState } from "react";
import { ShoppingCart } from "../components/cart/ShoppingCart";
import { useLocalStorage } from "../hooks/useLocalStorage";
import storeItems from "../data/items.json";

const ShoppingCartContext = createContext({
  openCart: () => {},
  closeCart: () => {},
  getItemQuantity: (id) => 0,
  increaseCartQuantity: (id) => {},
  decreaseCartQuantity: (id) => {},
  removeFromCart: (id) => {},
  addItemToCart: (id, quantity) => {}, 
  getItemName: (id) => "",
  getItemPrice: (id) => 0,
  cartQuantity: 0,
  cartItems: [],
});

export function useShoppingCart() {
  return useContext(ShoppingCartContext);
}

export function ShoppingCartProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const [cartItems, setCartItems] = useLocalStorage("shopping-cart", []);

  const cartQuantity = cartItems.reduce(
    (quantity, item) => item.quantity + quantity,
    0
  );

  const openCart = () => setIsOpen(true);
  const closeCart = () => setIsOpen(false);

  function getItemQuantity(id) {
    return cartItems.find((item) => item.id === id)?.quantity || 0;
  }

  function increaseCartQuantity(id) {
    setCartItems((currItems) => {
      if (currItems.find((item) => item.id === id) == null) {
        return [...currItems, { id, quantity: 1 }];
      } else {
        return currItems.map((item) => {
          if (item.id === id) {
            return { ...item, quantity: item.quantity + 1 };
          } else {
            return item;
          }
        });
      }
    });
  }

  function decreaseCartQuantity(id) {
    setCartItems((currItems) => {
      if (currItems.find((item) => item.id === id)?.quantity === 1) {
        return currItems.filter((item) => item.id !== id);
      } else {
        return currItems.map((item) => {
          if (item.id === id) {
            return { ...item, quantity: item.quantity - 1 };
          } else {
            return item;
          }
        });
      }
    });
  }

  function removeFromCart(id) {
    setCartItems((currItems) => {
      return currItems.filter((item) => item.id !== id);
    });
  }

  function addItemToCart(id, quantity = 1) {
    setCartItems((currItems) => {
      const existingItem = currItems.find((item) => item.id === id);
      
      if (existingItem) {
        
        return currItems.map((item) =>
          item.id === id ? { ...item, quantity: item.quantity + quantity } : item
        );
      } else {
      
        return [...currItems, { id, quantity }];
      }
    });
  }

  function getItemName(id) {
    return "Item Name";
  }

  function getItemPrice(id) {
    return 0;
  }

  function getTotalPrice() {
    const subtotal = cartItems.reduce((total, cartItem) => {
      const item = storeItems.find((i) => i.id === cartItem.id);
      return total + (item?.price || 0) * cartItem.quantity;
    }, 0);

    let shippingCharges = 49;
    if (subtotal <= 0 || subtotal >= 499) {
      shippingCharges = 0;
    }

    return subtotal + shippingCharges;
  }

  return (
    <ShoppingCartContext.Provider
      value={{
        getItemQuantity,
        increaseCartQuantity,
        decreaseCartQuantity,
        removeFromCart,
        addItemToCart,
        openCart,
        closeCart,
        getItemName,
        getItemPrice,
        cartItems,
        cartQuantity,
        getTotalPrice,
      }}
    >
      {children}
      <ShoppingCart isOpen={isOpen} />
    </ShoppingCartContext.Provider>
  );
}
