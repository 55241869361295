import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Kamagra } from "./components/home/Kamagra";
import { ShoppingCartProvider } from "./context/ShoppingCartContext";
import Footer from "./components/layout/Footer";
import Header from "./components/layout/Header";
import Banner from "./components/layout/Banner";
import ScrollToTop from "./utilities/ScrollToTop";
import NotFound from "./components/notfound/NotFound";
import Checkout from "./components/cart/Checkout";
import About from "./components/about/About";
import Reviews from "./components/about/Reviews";
import FAQ from "./components/about/FAQ";
import KamagraJelly from "./components/products/produkter/sildenafil/KamagraOralJelly";
import Viagra from "./components/products/produkter/sildenafil/Viagra";
import Lovegra from "./components/products/produkter/sildenafil/Lovegra";
import Vidalista from "./components/products/produkter/tadalafil/Vidalista";
import Cialis from "./components/products/produkter/tadalafil/Cialis";
import ApcalisJelly from "./components/products/produkter/tadalafil/ApcalisJelly";
import ValifJelly from "./components/products/produkter/vardenafil/ValifJelly";
import Kamagra100 from "./components/products/produkter/sildenafil/Kamagra100";
import KamagraGold from "./components/products/produkter/sildenafil/KamagraGold";
import SuperKamagra from "./components/products/produkter/sildenafil/SuperKamagra";
import Produkter from "./components/products/Potensmedel";
import Sildenafil from "./components/products/produkter/Sildenafil";
import Vardenafil from "./components/products/produkter/Vardenafil";
import Tadalafil from "./components/products/produkter/Tadalafil";
import SexLust from "./components/products/produkter/Sex-Lust";

import "./styles/app.scss";
import "./styles/header.scss";
import "./styles/kamagra.scss";
import "./styles/footer.scss";
import "./styles/checkout.scss";
import "./styles/about.scss";
import "./styles/notfound.scss";
import "./styles/cartItem.scss";
import "./styles/spinner.scss";
import "./styles/storeItem.scss";


function App() {
  return (
        <ShoppingCartProvider>  
          <Router>
            <Header />
            <Banner />
            <ScrollToTop />
            <Routes>
            <Route path="/" element={<Kamagra />} />
              <Route path="/OmOss" element={<About />} />
              <Route path="/Kassa" element={<Checkout />} />
              <Route path="/FAQ" element={<FAQ />} />
              <Route path="/Omdomen" element={<Reviews />} />
              <Route path="/Produkter/" element={<Produkter />} />
              <Route path="/Produkter/Sex-Lust" element={<SexLust />} />
              <Route path="/Produkter/Sildenafil/" element={<Sildenafil />} />
              <Route path="/Produkter/Vardenafil/" element={<Vardenafil />} />
              <Route path="/Produkter/Tadalafil/" element={<Tadalafil />} />
              <Route path="/Produkter/Sildenafil/Kamagra" element={<Kamagra100 />} />
              <Route path="/Produkter/Sildenafil/KamagraGold" element={<KamagraGold />} />
              <Route path="/Produkter/Sildenafil/KamagraOralJelly" element={<KamagraJelly />} />
              <Route path="/Produkter/Sildenafil/SuperKamagra" element={<SuperKamagra />} />
              <Route path="/Produkter/Sildenafil/Viagra" element={<Viagra />} />
              <Route path="/Produkter/Sildenafil/Lovegra" element={<Lovegra />} />
              <Route path="/produkter/Tadalafil/Vidalista" element={<Vidalista />} />
              <Route path="/produkter/Tadalafil/Cialis" element={<Cialis />} />
              <Route path="/produkter/Tadalafil/ApcalisOralJelly" element={<ApcalisJelly />} />
              <Route path="/produkter/Vardenafil/ValifOralJelly" element={<ValifJelly />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
          </Router>
        </ShoppingCartProvider>
  );
}

export default App;
